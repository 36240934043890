import _reactRouterProductionMin from "./umd/react-router.production.min.js";
/* eslint-env node */
export { _reactRouterProductionMin as default };
export const AbortedDeferredError = _reactRouterProductionMin.AbortedDeferredError,
  NavigationType = _reactRouterProductionMin.NavigationType,
  createPath = _reactRouterProductionMin.createPath,
  defer = _reactRouterProductionMin.defer,
  generatePath = _reactRouterProductionMin.generatePath,
  isRouteErrorResponse = _reactRouterProductionMin.isRouteErrorResponse,
  json = _reactRouterProductionMin.json,
  matchPath = _reactRouterProductionMin.matchPath,
  matchRoutes = _reactRouterProductionMin.matchRoutes,
  parsePath = _reactRouterProductionMin.parsePath,
  redirect = _reactRouterProductionMin.redirect,
  redirectDocument = _reactRouterProductionMin.redirectDocument,
  replace = _reactRouterProductionMin.replace,
  resolvePath = _reactRouterProductionMin.resolvePath,
  Await = _reactRouterProductionMin.Await,
  MemoryRouter = _reactRouterProductionMin.MemoryRouter,
  Navigate = _reactRouterProductionMin.Navigate,
  Outlet = _reactRouterProductionMin.Outlet,
  Route = _reactRouterProductionMin.Route,
  Router = _reactRouterProductionMin.Router,
  RouterProvider = _reactRouterProductionMin.RouterProvider,
  Routes = _reactRouterProductionMin.Routes,
  UNSAFE_DataRouterContext = _reactRouterProductionMin.UNSAFE_DataRouterContext,
  UNSAFE_DataRouterStateContext = _reactRouterProductionMin.UNSAFE_DataRouterStateContext,
  UNSAFE_LocationContext = _reactRouterProductionMin.UNSAFE_LocationContext,
  UNSAFE_NavigationContext = _reactRouterProductionMin.UNSAFE_NavigationContext,
  UNSAFE_RouteContext = _reactRouterProductionMin.UNSAFE_RouteContext,
  UNSAFE_mapRouteProperties = _reactRouterProductionMin.UNSAFE_mapRouteProperties,
  UNSAFE_useRouteId = _reactRouterProductionMin.UNSAFE_useRouteId,
  UNSAFE_useRoutesImpl = _reactRouterProductionMin.UNSAFE_useRoutesImpl,
  createMemoryRouter = _reactRouterProductionMin.createMemoryRouter,
  createRoutesFromChildren = _reactRouterProductionMin.createRoutesFromChildren,
  createRoutesFromElements = _reactRouterProductionMin.createRoutesFromElements,
  renderMatches = _reactRouterProductionMin.renderMatches,
  useActionData = _reactRouterProductionMin.useActionData,
  useAsyncError = _reactRouterProductionMin.useAsyncError,
  useAsyncValue = _reactRouterProductionMin.useAsyncValue,
  useBlocker = _reactRouterProductionMin.useBlocker,
  useHref = _reactRouterProductionMin.useHref,
  useInRouterContext = _reactRouterProductionMin.useInRouterContext,
  useLoaderData = _reactRouterProductionMin.useLoaderData,
  useLocation = _reactRouterProductionMin.useLocation,
  useMatch = _reactRouterProductionMin.useMatch,
  useMatches = _reactRouterProductionMin.useMatches,
  useNavigate = _reactRouterProductionMin.useNavigate,
  useNavigation = _reactRouterProductionMin.useNavigation,
  useNavigationType = _reactRouterProductionMin.useNavigationType,
  useOutlet = _reactRouterProductionMin.useOutlet,
  useOutletContext = _reactRouterProductionMin.useOutletContext,
  useParams = _reactRouterProductionMin.useParams,
  useResolvedPath = _reactRouterProductionMin.useResolvedPath,
  useRevalidator = _reactRouterProductionMin.useRevalidator,
  useRouteError = _reactRouterProductionMin.useRouteError,
  useRouteLoaderData = _reactRouterProductionMin.useRouteLoaderData,
  useRoutes = _reactRouterProductionMin.useRoutes,
  __esModule = _reactRouterProductionMin.__esModule;